import styled from "styled-components";

export const ActionMenuContainer = styled.div`
    position: relative;
`;

export const ActionIcon = styled.div`
    cursor: pointer;
    padding: 5px;
`;

export const ActionMenuList = styled.div`
    position: absolute;
    top: 30px;
    left: -220px;
    width: 240px;
    background: #FFF;
    box-shadow: 0px 3px 6px rgba(0,0,0,0.16);
    border-radius: 8px;
`;

export const List = styled.ul`
    list-style-type: none;
    padding: 0;
    margin: 0;
    li{
        padding: 10px;
        font-size: 14px;
        line-height: 22px;
        cursor:pointer;
        border-radius: 8px;
        &:hover{
            background: #44449B;
            color: #FFF;
            span{
                background: #FFF;
                color: #44449B;
            }
        }
        span{
            color: #FFF;
            font-size: 12px;
            background: #44449B;
            width: 25px;
            height: 25px;
            padding: 3px 4px;
            border-radius: 100%;
            margin-left: 10px;
        }
    }
`;