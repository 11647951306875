import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import callAPI from "../../../../../api/apiCaller";
import Button from "../../../../../common/Button";
import { ButtonSpacer } from "../../../../../common/Button/styles";
import Loading from "../../../../../common/Loading";
import Modal from "../../../../../common/Modal";
import {
  TaskMembersContainer,
  MemberRow,
  MemberInfo,
  MemberImage,
  MemberName,
  MemberActions,
} from "./styles";
import { Context } from "../../../../../Store";

function TaskMembers({ programType, mode, taskId, setShowTaskMembers }) {
  const [members, setMembers] = React.useState(null);
  const [isMarkingAsCompleted, setIsMarkingAsCompleted] = React.useState(false);
  const [isReminding, setIsReminding] = React.useState(false);
  const { state, dispatch } = React.useContext(Context);
  const params = useParams();
  const navigate = useNavigate();
  const completeAndNOtCompletedTasks = (data) => {
    const notCompletedTasks = [];
    if(programType==='TEAM'){

      data.forEach((task)=>{

      notCompletedTasks.push(...task.completedTaskByParticipants)
      notCompletedTasks.push(...task.notCompletedTaskByParticipants)
      })
      return notCompletedTasks
    }
   
else{
    data.forEach((task) => {
      if (
        mode === "pending" &&
        task.notCompletedTaskByParticipants.length > 0
      ) {
        notCompletedTasks.push(...task.notCompletedTaskByParticipants);
      } else if (
        mode === "complete" &&
        task.completedTaskByParticipants.length > 0
      ) {
        notCompletedTasks.push(...task.completedTaskByParticipants);
      }
    });

    return notCompletedTasks;
  }
  };


  function getProgramTaskParticipients() {
    callAPI(dispatch, "getProgramTaskMembers", {
      id: taskId,
    }).then((res) => {
    setMembers(completeAndNOtCompletedTasks([res.data])) 
      
    });
  }
  React.useEffect(() => {
    getProgramTaskParticipients();
  }, []);
  function remindUser(userId) {
    setIsReminding(userId);
    if(programType!=='TEAM'){

    
    return callAPI(dispatch, "remindUser", {
      id: +params.selectedProgram,
      taskId,
      participantId: userId,
    }).then((res) => {
      setIsReminding(false);
      setIsMarkingAsCompleted(false);
    });
  }else{
    return callAPI(dispatch, "remindUser", {
      id: +params.selectedProgram,
      taskId,
      teamId: userId,
    }).then((res) => {
      setIsReminding(false);
      setIsMarkingAsCompleted(false);
    });
  }
  }

  function MarkTeamTaskCompleted(teamId){
    setIsMarkingAsCompleted(teamId);
    callAPI(dispatch, 'markTeamTaskAsComplete', { id:teamId , status:'completed' }).then((updatedTasks) => {
      setIsMarkingAsCompleted(false);
    getProgramTaskParticipients();


    })
  }

  function markTaskAsComplete(userId) {
    setIsMarkingAsCompleted(userId);
    return callAPI(dispatch, "markTaskAsCompleteForUser", {
      id: params.selectedProgram,
      tid:taskId,
      rid: userId,
    }).then((res) => {
      setIsMarkingAsCompleted(false);
      getProgramTaskParticipients();
    });
  }
  function remindAll() {
    const participants = members.map((member) => {
      if(programType=== "TEAM"){
        return {
          taskId: taskId,
          teamId: member?.teamId,
        };  
      }
      else{
      return {
          taskId: taskId,
          participantId: member?.participant?.id,
        };
      }
    });
    setIsReminding("all");
    return callAPI(dispatch, "remindAll", {
      id: params.selectedProgram,
      participants,
    }).then((res) => {
      setIsReminding(false);
      setShowTaskMembers(false);
    });
  }
  function markTeamAllAsComplete() {
    setIsMarkingAsCompleted("all");
    const ids = members.map((member) =>member.id );
    // console.log(ids,"<<Ids");
    return callAPI(dispatch, "markTeamAllTaskAsComplete", {
      ids,
      status:'completed',
    }).then(() => {
      setIsMarkingAsCompleted(false);
      setShowTaskMembers(false);
    });
  }
  function markAllAsComplete() {
    setIsMarkingAsCompleted("all");
    const participants = members.map((member) => {
   
      return {
          taskId: taskId,
          participantId: member?.participant?.id,
        };
    });
    return callAPI(dispatch, "markAllTaskAsComplete", {
      id: params.selectedProgram,
      participants,
    }).then(() => {
      setIsMarkingAsCompleted(false);
      setShowTaskMembers(false);
    });
  }
  function getTitle(){

    if(mode==='pending' && programType!=='TEAM'){
      return "Members yet to complete task"
    }

    else if(mode!=='pending' && programType!=='TEAM'){
      return "Members who have completed the task"
    }

    else if(mode==='pending' && programType==='TEAM'){
      return "Teams yet to complete task"
    }

    else{
      return "Teams who have completed the task"
    }

  
  }
  if (members === null) {
    return (
      <Modal
        title={
          getTitle()
        }
        onClose={() => {
          setShowTaskMembers(false);
        }}
      >
        <Loading item="Members" />
      </Modal>
    );
  }
  return (
    <Modal
      title={
        getTitle()
      }
      onClose={() => {
        setShowTaskMembers(false);
      }}
      actions={
        mode === "pending"
          ? [
              {
                text: "Cancel",
                onClick: () => {
                  setShowTaskMembers(false);
                },
                icon: "cross",
              },
              {
                text: "Remind All",
                onClick: remindAll,
                variant: "primary",
                icon: isReminding === "all" ? "loading" : "notification",
              },
              {
                text: "Mark All As Complete",
                onClick:programType==="TEAM" ? markTeamAllAsComplete: markAllAsComplete,
                variant: "primary",
                icon: isMarkingAsCompleted === "all" ? "loading" : "tick",
              },
            ]
          : [
              {
                text: "Cancel",
                onClick: () => {
                  setShowTaskMembers(false);
                },
                icon: "cross",
              },
            ]
      }
    >
      <TaskMembersContainer>
        { programType==='TEAM' ?<>
        {console.log(members,"Members>>")}
        
        {
 members.map((member) => {

  return (
    <>
    { mode==='pending' && member.status==='pending'?
    <MemberRow key={member?.team?.id}>
      <MemberInfo>
        <MemberImage
          src={
            member?.team
              ? member?.team?.media
              : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"
          }
        />
        <MemberName>
          {member?.team?.name}
        </MemberName>
      </MemberInfo>
      <MemberActions>
        {/* <Button
          icon="chat"
          // onClick={() => {
          //   navigate(
          //     `/messages/${
          //       member.participant.id
          //         ? member.participant.id
          //         : member.participant.participant.id
          //     }`
          //   );
          // }}
        ></Button> */}
        {mode === "pending" && (
          <>
            <ButtonSpacer />
            <Button
              onClick={() => {
                remindUser(member?.teamId);
              }}
              icon={
                isReminding === member?.teamId
                  ? "loading"
                  : "notification"
              }
              text="Remind"
            />
          </>
        )}
        {mode === "pending" && (
          <>
            <ButtonSpacer />
            <Button
              icon={
                isMarkingAsCompleted === member?.taskId
                  ? "loading"
                  : "tick"
              }
              text="Mark as Completed"
              onClick={() => {
                MarkTeamTaskCompleted(member?.id);
              }}
            />
          </>
        )}
      </MemberActions>
    </MemberRow> :
    mode==='complete' && member.status==="completed" ?
    <MemberRow key={member?.team?.id}>
    <MemberInfo>
      <MemberImage
        src={
          member?.team
            ? member?.team?.media
            : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"
        }
      />
      <MemberName>
        {member?.team?.name}
      </MemberName>
    </MemberInfo>
    <MemberActions>
      {/* <Button
        icon="chat"
        // onClick={() => {
        //   navigate(
        //     `/messages/${
        //       member.participant.id
        //         ? member.participant.id
        //         : member.participant.participant.id
        //     }`
        //   );
        // }}
      ></Button> */}
      {/* { mode==='complete' && (
        <>
          <ButtonSpacer />
          <Button
            onClick={() => {
              remindUser(member?.teamId);
            }}
            icon={
              isReminding === member?.teamId
                ? "loading"
                : "notification"
            }
            text="Remind"
          />
        </>
      )} */}
    </MemberActions>
  </MemberRow>:''
 }
    </>
  );
})
        
}
        </> : members.map((member) => {
          return (
            <MemberRow key={member.participant.id}>
              <MemberInfo>
                <MemberImage
                  src={
                    member.participant?.image != null
                      ? member.participant?.image
                      : member.participant.participant?.image != null
                      ? member.participant.participant?.image
                      : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"
                  }
                />
                <MemberName>
                  {member.participant?.firstName
                    ? member.participant?.firstName
                    : member.participant?.participant?.firstName}
                  &nbsp;
                  {member.participant?.lastName
                    ? member.participant?.lastName
                    : member.participant?.participant?.lastName}
                </MemberName>
              </MemberInfo>
              <MemberActions>
                <Button
                  icon="chat"
                  onClick={() => {
                    navigate(
                      `/messages/${
                        member.participant.uuid
                          ? member.participant.uuid
                          : member.participant.participant.uuid
                      }`
                    );
                  }}
                ></Button>
                {mode === "pending" && (
                  <>
                    <ButtonSpacer />
                    <Button
                      onClick={() => {
                        remindUser(member.participant.id);
                      }}
                      icon={
                        isReminding === member.participant.id
                          ? "loading"
                          : "notification"
                      }
                      text="Remind"
                    />
                  </>
                )}
                {mode === "pending" && (
                  <>
                    <ButtonSpacer />
                    <Button
                      icon={
                        isMarkingAsCompleted === member.participant.id
                          ? "loading"
                          : "tick"
                      }
                      text="Mark as Completed"
                      onClick={() => {
                        markTaskAsComplete(member.participant.id);
                      }}
                    />
                  </>
                )}
              </MemberActions>
            </MemberRow>
          );
        })}
      </TaskMembersContainer>
    </Modal>
  );
}
export default TaskMembers;
