export const UpcomingCount=(count) => {
    return {
      type:"UpcomingMeetingsCount",
      count,
      segmentName: "UpcomingMeetingsCount",
    }
  }

  export const CompletedCount=(count) => {
    return {
      type:"CompletedMeetingsCount",
      count,
      segmentName: "CompletedMeetingsCount",
    }
  }

  export const UnacceptedCount=(count) => {
    return {
      type:"UnacceptedMeetingCount",
      count,
      segmentName: "UnacceptedMeetingCount",
    }
  }

  export const UpcomingTeamCount=(count) => {
    return {
      type:"UpcomingTeamMeetingCount",
      count,
      segmentName: "UpcomingTeamMeetingCount",
    }
  }

  export const CompletedTeamCount=(count) => {
    return {
      type:"CompletedTeamMeetingCount",
      count,
      segmentName: "CompletedTeamMeetingCount",
    }
  }

  export const RequestCount = (count) => {
    return {
      type:"RequestedMeetingCount",
      count,
      segmentName: "RequestedMeetingCount",
    }
  }