import styled from "styled-components";
import colors from "../../config/colors";
import { panelSpacing } from "../styles";

export const AccordianContainer = styled.div`
    border:solid 1px ${colors.secondary};
    border-radius: ${panelSpacing/2}px;
    transition: all 0.1s ease-in-out;
    width:100%;
    height:fit-content;
    height: ${props=>props.isTask ?`calc(100vh - 250px)` : !props.height ?  'fit-content' : '613px'};
    overflow-y: scroll;

    ::-webkit-scrollbar {
        width: 5px;
    }
 
    @media (max-width:767px) {
    height: ${props=>props.isTask ?`calc(100vh - 350px)` : !props.height ?  'fit-content' : '613px'};
    }
`;
export const SectionContainer = styled.div`
    border-bottom: solid 1px ${colors.secondary};
    padding:${panelSpacing/2}px;
    transition: all 0.1s ease-in-out;
    :last-of-type{
        border-bottom: none;
    }
`;
export const SectionHeader = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;
export const SectionInfo = styled.div`
    width:${props=>props.isFullWidth ? "100%" : " calc(100% - 40px)"};
`;
export const SectionContent = styled.div`
    margin-top: ${panelSpacing/2}px;
    background-color:${colors.secondary};
    padding:${panelSpacing/2}px;
    border-radius:${panelSpacing/2}px;
`;
export const ContentCard= styled.div`
    background-color:${colors.background};
    padding:${panelSpacing/2}px;
    border-radius:${panelSpacing/2}px;
`;
export const ContentActions = styled.div`
    display:flex;
    margin-top:${panelSpacing/2}px;
    align-items:center;
    justify-content:flex-end;
    @media (max-width:640px) {
        flex-direction: column;
        align-items: flex-start;
    }
`;