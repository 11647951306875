import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import callAPI from "../../../../api/apiCaller";
import Button from "../../../../common/Button";
import Labelize from "../../../../common/Labelize";
import Loading from "../../../../common/Loading";
import Modal from "../../../../common/Modal";
import TextInput from "../../../../common/TextInput";
import { SearchTextInput } from "../../../../common/TextInput/SearchableTextInput";
import { formatMessageDate, validateEmail } from "../../../../common/utils";
import { socket } from "../../../../common/chat-socket";
import { ConversationList } from "../../../../Store/ConversationList/actions";
import {
  MessageListContainer,
  MessageListHeader,
  MessageListTitle,
  MessageContact,
  ContactImage,
  MessageInfo,
  ContactName,
  MessageSummary,
  MessageTime,
  MessageListButton,
  Personal,
  Group,
  ItemNumber,
  Indicator,
  TimeContainer,
  PersonalChats,
  ConversationData
} from "./styles";
import { Context } from "../../../../Store";
import {
  CrossButtonContainer,
  CrossButtonContainerSpan,
} from "../../Programs/ProgramsList/styles";
import GroupMessageList from "../GroupMessageList";
import { FixedSizeList } from "react-window";
import { getUserInfo } from "../../../../Store/User/accessors";
import { markAsReadMessage } from "../../../../Store/UnreadMessage/actions";
import { getPersonalUnreadMsg } from "../../../../Store/PersonalMessageCount/accessors";
import { markAsReadPersonalMessage } from "../../../../Store/PersonalMessageCount/actions";
import { markAsReadGroupMessage } from "../../../../Store/GroupMessageCount/actions";
import { SearchGroupChat } from "../../../../common/TextInput/SearchableGroupChat";
import { getGroupUnreadMsg } from "../../../../Store/GroupMessageCount/accessors";
function MessageList(props) {
  const [searchText, setSearchText] = React.useState("");

  const [messageList, setMessageList] = React.useState(null);
  const [showNewMessage, setShowNewMessage] = React.useState(false);
  const [newEmail, setNewEmail] = React.useState("");
  const [isCheckingEmail, setIsCheckingEmail] = React.useState(false);
  const [reloadGroupMessage, setReloadGroupMessage] = React.useState(false);
  const [isEmailValid, setIsEmailValid] = React.useState(null);
  const [isLoadingGroupMessage, setIsLoadingGroupMessage] =
    React.useState(null);
  const { state, dispatch } = React.useContext(Context);
  const [unReadMessage, setunReadMessage] = React.useState(0);
  const [groupMessage, setGroupMessage] = React.useState(null);
  const [unreadCount, setUnreadCount] = React.useState(null);
  const [page, setPage] = React.useState(1);
  const [limit, setLimit] = React.useState(15);
  const [loading, setLoading] = React.useState(false);
  const [totalPages, setTotalPages] = useState(1);
  const [newGroupIndicator, setNewGroupIndicator]= useState(getGroupUnreadMsg(state).messages)
  

  const location = useLocation();
  const [isGroupState, setIsGroupState] = React.useState(
    location.pathname.includes("/group")
  );

  const navigate = useNavigate();
  const params = useParams();

  const currentUserID = getUserInfo(state)?.id;
  function getGroupMessage() {
    //here id is page number
    callAPI(dispatch, "getInitiatedGroupMessageList").then((res) => {
      setGroupMessage(res.data);
      dispatch(markAsReadGroupMessage(res.data))
    });
  }



  React.useEffect(() => {
    setTimeout(() => {
      isGroupState && getGroupMessage();
      if (!isGroupState) {
        callAPI(dispatch, "getInitiatedMessageList",
          {page: 1,  take: +limit}).then((res) => {
            const filteredMessages = res.data.filter((message) => {
              const receiverName = `${message?.receiver?.firstName} ${message?.receiver?.lastName}`;
              return (
                receiverName.toLowerCase().startsWith(searchText.toLowerCase()) ||
                message?.lastMessage?.message
                  .toLowerCase()
                  .startsWith(searchText.toLowerCase())
              );
            });
          setMessageList(filteredMessages);
          setTotalPages(res.total);
          const unreadMessagesCount = res.data.filter(message => message?.lastMessage?.isRead === false  && message?.lastMessage?.user?.id !== getUserInfo(state).id).length; 
          dispatch(markAsReadPersonalMessage(unreadMessagesCount))
          setUnreadCount(res.data?.filter((message) => message?.unreadCount));
          setPage(1);
        });
      }
    },50)
    socket.on("privateMessage", (msg) => {
      setReloadGroupMessage(!reloadGroupMessage);
    });
  }, [reloadGroupMessage, searchText, props.refresh]);
 

    const fetchMessages = useCallback(async () => {
    try {
      const res = await callAPI(dispatch, "getMessageList");
      const messages = Array.isArray(res.data) ? res.data : [];
      dispatch(ConversationList(messages));

      const userId = getUserInfo(state).id;
      const unreadMessagesCount = messages.filter(
        message => message?.lastMessage?.isRead === false && message?.lastMessage?.user?.id !== userId
      ).length;

      dispatch(markAsReadPersonalMessage(unreadMessagesCount));
    } catch (error) {
      console.error("Failed to fetch messages:", error);
    }
  }, []);

  useEffect(() => {
    fetchMessages();
  }, []);

  const handleScroll = async (e) => {
    const { scrollTop, scrollHeight, clientHeight } = e.target;
    if (scrollTop + clientHeight >= scrollHeight && !loading && page < totalPages) {
        setLoading(true);
        setPage(prevPage => prevPage + 1); // Update page using a callback
        callAPI(dispatch, "getInitiatedMessageList", {
            page: page + 1, // Use updated page value
            take: limit,
        }).then((res) => {
            setMessageList((prev) => [...prev, ...res.data]);
            const unreadMessagesCount = res.data.filter(message => message?.lastMessage?.isRead === false && message?.lastMessage?.user?.id !== getUserInfo(state).id).length; 
            dispatch(markAsReadPersonalMessage(unreadMessagesCount));
            setUnreadCount(res.data?.filter((message) => message?.unreadCount));
            setLoading(false);
        });
    }
  };

  function hasUnreadMessage(dispatch, setunReadMessage) {
    let unReadMessage, groupMessageIndicator;

    // First, fetch the total unread count of messages
    callAPI(dispatch, "unReadMessage")
      .then((res) => {
        const totalUnreadCount = res.data.totalUnreadCount;
        setunReadMessage(totalUnreadCount);
        unReadMessage = totalUnreadCount; // Store the value for later use

        // After getting the unread count, check for unread group messages
        callAPI(dispatch, "getGroupMessageList")
          .then((res) => {
            const hasUnreadGroupMessage = res.data.some((sms) =>
              sms.messages.some(
                (msg) =>
                  msg?.userId !== getUserInfo(state)?.id &&
                  !msg?.readBy?.includes(getUserInfo(state).id)
              )
            );
            groupMessageIndicator = hasUnreadGroupMessage; // Store the value for later use

            // Dispatch the action after both asynchronous operations complete
            dispatch(markAsReadMessage(unReadMessage, groupMessageIndicator));
          })
          .catch((error) => {
            console.error("Error fetching group messages:", error);
          });
      })
      .catch((error) => {
        console.error("Error fetching unread message count:", error);
      });
  }

  const handleRowClick = useCallback(
    (message) => {
      navigate("/messages/" + message?.receiver?.uuid);
      if (!message?.lastMessage?.isRead) {
        callAPI(dispatch, "markConversationAsRead", {
          id: message.id,
        }).then((res) => {
          hasUnreadMessage(dispatch, setunReadMessage);
          
        });
        setMessageList(prevList => prevList.filter((ele)=>{
          if(ele == message && !ele.lastMessage.isRead){
            ele.lastMessage.isRead=true;
            return ele
          }else{
            return ele
          }
        })
      )
      setTimeout(() => {
        callAPI(dispatch, "getInitiatedMessageList" ,{page : page , take: +limit}).then((res) => {
          const unreadMessagesCount = res.data.filter(message => message?.lastMessage?.isRead === false  && message?.lastMessage?.user?.id !== getUserInfo(state).id).length; 
          dispatch(markAsReadPersonalMessage(unreadMessagesCount));
          setUnreadCount(res.data?.filter((message) => message?.unreadCount));
        });
      },50)
      }
      else{
        callAPI(dispatch, "getInitiatedMessageList" ,{page : page , take: +limit}).then((res) => {
           const unreadMessagesCount = res.data.filter(message => message?.lastMessage?.isRead === false  && message?.lastMessage?.user?.id !== getUserInfo(state).id).length; 
            dispatch(markAsReadPersonalMessage(unreadMessagesCount))
            setUnreadCount(res.data?.filter((message) => message?.unreadCount));
         });
      }

      props.setHamburgerOpen(!props.hamburgerOpen);
    },
    [navigate, props, dispatch]
  );


  useEffect(()=>{

    if(props.receiverData){
      if (!props.receiverData?.lastMessage?.isRead) {
        callAPI(dispatch, "markConversationAsRead", {
          id: props.receiverData.user.conversationId,
        }).then((res) => {
          hasUnreadMessage(dispatch, setunReadMessage);
        }); 
        callAPI(dispatch, "getInitiatedMessageList" ,{page : page , take: +limit}).then((res) => {
            const unreadMessagesCount = res.data.filter(message => message?.lastMessage?.isRead === false  && message?.lastMessage?.user?.id !== getUserInfo(state).id).length; 
            dispatch(markAsReadPersonalMessage(unreadMessagesCount))
        });
      }
    } 

  },[props.receiverData])

  const SearchableItemClick = (item) => {
    const dateTime = new Date();
    item.createdAt = dateTime;
    if (!isGroupState && messageList !== null) {
        const foundItem = messageList.find(msg => msg.receiver && msg.receiver.id === item.receiver.id);
        if (!foundItem) {
            setMessageList([item, ...messageList]);
        }
        navigate("/messages/" + item.receiver.uuid);
    }
    else{
      if(groupMessage !== null){
        const foundItem = groupMessage.find(msg => msg.id && msg.id === item.id);
        if (!foundItem) {
          setGroupMessage([item, ...groupMessage]);
        }
        navigate("/messages/group/" + item.id);
      }
    }
  };

  return (
    <MessageListContainer hamburgerOpen={props.hamburgerOpen}>
      <MessageListHeader>
        <CrossButtonContainer
          onClick={() => props.setHamburgerOpen(!props.hamburgerOpen)}
        >
          <CrossButtonContainerSpan></CrossButtonContainerSpan>
          <CrossButtonContainerSpan></CrossButtonContainerSpan>
        </CrossButtonContainer>
        <MessageListTitle>
          {isGroupState ? "Group Chat" : "Messages"}
          {/* <Button icon="chat" variant={'primary'} text={!isGroupMessage ?'Group Chats':'Messages'} onClick={() =>setIsGroupMessage((pre)=>!pre) } /> */}
        </MessageListTitle>
        <MessageListButton>
          <Personal>
            <Button
              simple={true}
              borderLeft={true}
              text={"Personal"}
              // icon={"notification-active"}
              variant={!isGroupState ? "primary" : "secondary"}
              onClick={() => {
                navigate("/messages");
              }}
              className={'chat-button'}
            />
            {getPersonalUnreadMsg(state).count > 0  ? <ItemNumber active={!isGroupState ? true : false} /> : ""}
          </Personal>
          <Group>
            <Button
              simple={true}
              borderRight={true}
              // icon={"notification-active"}
              text={"Groups"}
              variant={isGroupState ? "primary" : "secondary"}
              onClick={() => {
                navigate("/messages/group");
              }}
              className={'chat-button'}
            />
            {/* {groupMessageUnread?.length ? <ItemNumber ></ItemNumber>:''} */}
            {getGroupUnreadMsg(state).messages &&
              getGroupUnreadMsg(state).messages.some((sms) =>
                sms.messages.some(
                  (msg) =>
                    msg?.userId !== getUserInfo(state)?.id &&
                    !msg?.readBy?.includes(getUserInfo(state).id)
                )
              ) && <ItemNumber active={isGroupState ? true : false} />}
          </Group>
        </MessageListButton>
        {isGroupState ?      
          <SearchGroupChat
            SearchableItemClick={SearchableItemClick} 
            placeholder="Search"
          /> : 
          <SearchTextInput
            SearchableItemClick={SearchableItemClick} 
            placeholder="Search" 
          />
        }
      </MessageListHeader>

      {groupMessage === null && isGroupState ? (
        <Loading loadingItem="Group Messages" />
      ) : isGroupState ? (
        <GroupMessageList
          refresh={props.refresh}
          setRefresh={props.setRefresh}
          searchText={searchText}
          groupMessageList={groupMessage}
          getGroupMessage={getGroupMessage}
          setHamburgerOpen={props.setHamburgerOpen}
          hamburgerOpen={props.hamburgerOpen}
          setIsLoadingGroupMessage={setIsLoadingGroupMessage}
        />
      ) : null}
      {messageList === null && !isGroupState ? (
        <Loading loadingItem="Messages" />
      ) : !isGroupState ? (
            <PersonalChats onScroll={handleScroll}>
              {messageList && messageList.map((item) => {
                return(
                <MessageContact
                  key={item.id}
                  onClick={() => handleRowClick(item)}
                  active={params.user == item.receiver.uuid}
                >
                  <ConversationData>
                    <ContactImage
                      src={ 
                        item.receiver.id === params.user
                          ? item.lastMessage.sender.image
                          : item.receiver.image
                          ? item.receiver.image
                          : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"
                      }
                    />
                    <MessageInfo>
                      <ContactName>
                        {item.receiver.id === params.user
                          ? `${item.lastMessage.sender.firstName} ${item.lastMessage.sender.lastName}`
                          : `${item.receiver.firstName} ${item.receiver.lastName}`}
                      </ContactName>
                      <MessageSummary>
                      {item.lastMessage && item.lastMessage.message ? (
                          item.lastMessage.message.length > 28 ? (
                              `${item.lastMessage.message.slice(0, 28)}...`
                          ) : (
                              item.lastMessage.message
                          )
                      ) : (
                          ""
                      )}
                      </MessageSummary>
                    </MessageInfo>
                    </ConversationData>
                  <TimeContainer>
                    {item.lastMessage && !item.lastMessage.isRead &&
                      item.lastMessage.userId !==
                        currentUserID && <Indicator />}
                    <MessageTime>
                      {formatMessageDate(
                        item.lastMessage
                          ? item.lastMessage.createdAt
                          : item.createdAt
                      )}
                    </MessageTime>
        
                  </TimeContainer>
                </MessageContact>
                )
              })}
              {loading && <Loading loadingItem="conversations" isLoading={true}/>}
            </PersonalChats>
      ) : null}
      {showNewMessage && (
        <Modal
          title={"New Message"}
          onClose={() => {
            setShowNewMessage(false);
          }}
          actions={[
            {
              text: "Cancel",
              icon: "cross",
              onClick: () => {
                setShowNewMessage(false);
              },
            },
            {
              text: "Send Message",
              icon: isCheckingEmail ? "loading" : "send",
              variant: "primary",
              disabled: !isEmailValid,
              onClick: () => {
                setIsCheckingEmail(true);
                callAPI(dispatch, "getUserFromEmail", { newEmail }).then(
                  (res) => {
                    setIsCheckingEmail(false);
                    setShowNewMessage(false);

                    navigate("/messages/" + res.id);
                  }
                );
              },
            },
          ]}
        >
          <Labelize label="Enter Email">
            <TextInput
              placeholder="Enter Email"
              value={newEmail}
              setValue={setNewEmail}
              isValid={isEmailValid}
              setIsValid={setIsEmailValid}
              validator={validateEmail}
            />
          </Labelize>
        </Modal>
      )}
    </MessageListContainer>
  );
}
export default MessageList;
