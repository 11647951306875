
export const OpenNetworkProgramTabs={
    "TEAM":{
        "dashboard":[
            // { label: "Request", value: "Request" },
            // { label: "Outbound", value: "Outbound" },
            { label: "Upcoming Meetings", value: "Upcoming" },
            { label: "Completed Meetings", value: "Past" },
            // { label: "Archived", value: "Archived" },
        ],
    },
    "OPEN_NETWORK":{
        "dashboard":[
            // { label: "Request", value: "Request" },
            // { label: "Outbound", value: "Outbound" },
            { label: "Upcoming Meetings", value: "Upcoming" },
            { label: "Completed Meetings", value: "Past" },
            // { label: "Archived", value: "Archived" },

        ],
        "mentor_dashboard":[
            // { label: "Outbound", value: "Outbound" },
            { label: "Upcoming Meetings", value: "Upcoming" },
            { label: "New Meeting Requests", value: "Requests" },
            { label: "Completed Meetings", value: "Past" },
            { label: "Archived", value: "Archived" },

        ],
        "mentee_dashboard":[
            // { label: "Inbound", value: "Inbound" },
            // { label: "Outbound", value: "Outbound" },
            { label: "Upcoming Meetings", value: "Upcoming" },
            { label: "Completed Meetings", value: "Past" },
            { label: "Unaccepted Meetings", value: "unfulfilled" },
            // { label: "Archived", value: "Archived" },

        ],
        "mentor_manager":[
            // { label: "Outbound", value: "Outbound" },
            { label: "Upcoming Meetings", value: "Upcoming" },
            { label: "New Meeting Requests", value: "Requests" },
            { label: "Completed Meetings", value: "Past" },
            { label: "Archived", value: "Archived" },
        ]
    },
    "ONE_ONE_NETWORK":{
        "dashboard":[
            { label: "Upcoming Meetings", value: "Upcoming" },
            // { label: "Outbound", value: "Outbound" },
            { label: "New Meeting Requests", value: "Requests" },
            { label: "Completed Meetings", value: "Past" },
            // { label: "Archived", value: "Archived" },

        ],
        "mentor_manager":[
            { label: "Upcoming Meetings", value: "Upcoming" },
            // { label: "Outbound", value: "Outbound" },
            { label: "New Meeting Requests", value: "Requests" },
            { label: "Completed Meetings", value: "Past" },
            // { label: "Archived", value: "Archived" },

        ],
        "mentee":[
            // { label: "Requests", value: "Requests" },
            // { label: "Outbound", value: "Outbound" },
            { label: "Upcoming Meetings", value: "Upcoming" },
            { label: "Completed Meetings", value: "Past" },
            { label: "Unaccepted Meetings", value: "unfulfilled" },
            // { label: "Archived", value: "Archived" },

        ],
    },
}