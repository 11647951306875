export function getUpcomingMeetingsCount(state) {
    return {
        count : state.UpcomingMeetingsCount?.count || null ,
    }
}

export function getCompletedMeetingsCount(state) {
    return {
        count : state.CompletedMeetingsCount?.count || null ,
    }
}

export function getUnacceptedMeetingsCount(state) {
    return {
        count : state.UnacceptedMeetingCount?.count || null ,
    }
}

export function getUpcomingTeamMeetingsCount(state) {
    return {
        count : state.UpcomingTeamMeetingCount?.count || null ,
    }
}

export function getCompletedTeamMeetingsCount(state) {
    return {
        count : state.CompletedTeamMeetingCount?.count || null ,
    }
}

export function getRequestedMeetingsCount(state) {
    return {
        count : state.RequestedMeetingCount?.count || null,
    }
}