import React from "react";
import { TabsContainer,Tab, ItemNumber } from "./styles";
import { Context } from "../../Store";
import { getUpcomingMeetingsCount, getCompletedMeetingsCount } from "../../Store/ProgramMeetingCount/accessors";

/**
 * For sake of transparency, all components would not store any state on its own.
 * 
 * Options consisit of the following information:
 * 1. value: The value of the option
 * 2. label: The label of the option
 */
function Tabs({options,value,setValue,count,isWidth}) {
  const { dispatch, state } = React.useContext(Context);
  const RenderComponent = (value) => {
    switch (value) {
      case  "Requests":
        return count?.newRequests ;
      case "Upcoming":
        return getUpcomingMeetingsCount(state).count;
      case "Past":
        return getCompletedMeetingsCount(state).count;
      case "Archived":
        return count?.archivedRequests ;
    }
  };

return <TabsContainer isWidth={isWidth}>
    {
      options?.map((option, i)=> 
        <Tab isWidth={isWidth}
         key={option.value} active={value===option.value} onClick={()=>{
          setValue(option.value);
        }}>{option.label}
        {RenderComponent(option.value) &&  <ItemNumber> {RenderComponent(option.value)} </ItemNumber>  }
        </Tab>
      )
    }
  </TabsContainer>;
}
export default Tabs;