import React, { useState } from "react";
import {
  PasswordLabel,
  TextInputContainer,
  Input,
  TextArea,
  WordCount,
  ViewPasswordContainer,
  PasswordHintContainer,
  DivSpacer,
  InstructionsStar,
  InstructionsStarSpan,
  PaymentInput,
} from "./styles";
import Icon from "../Icon";
import colors from "../../config/colors";
import {
  atLeastLengthOfEight,
  hasAtleastOneNumber,
  hasSpecialCharacter,
  maximumLengthOfTwenty
} from "../utils";

function TextInput({
  value,
  setValue,
  placeholder = "Placeholder",
  type = "text",
  disabled = false,
  wordLimit = 0,
  validator = () => true,
  isValid = null,
  isTextArea = false,
  isNotRound = false,
  setIsValid = () => {},
  isHeight = false,
  mode,
  hideInstruction,
  min = 0,
  onKeyDown,
}) {
  React.useEffect(() => {
    value &&
      setIsValid(
        (wordLimit !== 0 ? value.length <= wordLimit : true) && validator(value)
      );
  }, []);

  let Component = isTextArea ? TextArea : Input;
  let InputPayment = PaymentInput;
  const [textType, setTextType] = useState(type);

  function toggleTextType() {
    textType === "password" ? setTextType("text") : setTextType("password");
  }
  if (isNotRound) {
    return (
      <TextInputContainer>
        <InputPayment
          isValid={isValid}
          value={value}
          placeholder={""}
          type={textType}
          isTextArea={isTextArea}
          disabled={disabled}
          min={min}
          onKeyDown={onKeyDown}
          onChange={(e) => {
            if (disabled) {
              return;
            }
            setValue(e.target.value);
            setIsValid(
              (wordLimit !== 0 ? e.target.value.length <= wordLimit : true) &&
                validator(e.target.value)
            );
          }}
          rows={4}
          cols={50}
        />
      </TextInputContainer>
    );
  }
  return (
    <>
      <TextInputContainer>
        <Component
          isValid={isValid}
          value={value}
          placeholder={isNotRound ? "" : placeholder}
          type={textType}
          isTextArea={isTextArea}
          isHeight={isHeight}
          disabled={disabled}
          onKeyDown={onKeyDown}
          onChange={(e) => {
            if (disabled) {
              return;
            }
            setValue(e.target.value);
            setIsValid(
              (wordLimit !== 0 ? e.target.value.length <= wordLimit : true) &&
                validator(e.target.value)
            );
          }}
          rows={4}
          cols={50}
          min={new Date().toISOString().slice(0, 16)}
        />

        {type === "password" && (
          <ViewPasswordContainer onClick={() => toggleTextType()}>
            <Icon
              type={`${textType === "password" ? "view" : "show"}`}
              size="17px"
              color="#211717a6"
            />
          </ViewPasswordContainer>
        )}

        {wordLimit > 0 && (
          <WordCount isAboveLimit={value.length > wordLimit}>
            {value.length}/{wordLimit}
          </WordCount>
        )}
      </TextInputContainer>

      <DivSpacer />
      {type === "password" && mode !== "login" && !hideInstruction && (
        <PasswordHintContainer onClick={() => toggleTextType()}>
          <InstructionsStar>
            <InstructionsStarSpan
              color={
                maximumLengthOfTwenty(value) ? colors.success : colors.danger
              }
            >
              *
            </InstructionsStarSpan>
            <PasswordLabel color={colors.primary}>
              Maximum of 20 characters,
            </PasswordLabel>
          </InstructionsStar>
          <InstructionsStar>
            <InstructionsStarSpan
              color={
                atLeastLengthOfEight(value) ? colors.success : colors.danger
              }
            >
              *
            </InstructionsStarSpan>
            <PasswordLabel color={colors.primary}>
              Minimum of 8 characters,
            </PasswordLabel>
          </InstructionsStar>

          <InstructionsStar>
            <InstructionsStarSpan
              color={
                hasSpecialCharacter(value) ? colors.success : colors.danger
              }
            >
              *
            </InstructionsStarSpan>
            <PasswordLabel color={colors.primary}>
              include at least 1 special character,
            </PasswordLabel>
          </InstructionsStar>

          <InstructionsStar>
            <InstructionsStarSpan
              color={
                hasAtleastOneNumber(value) ? colors.success : colors.danger
              }
            >
              *
            </InstructionsStarSpan>
            <PasswordLabel color={colors.primary}>
              at least 1 number
            </PasswordLabel>
          </InstructionsStar>
        </PasswordHintContainer>
      )}
    </>
  );
}

export default TextInput;
