import React from "react";
import { UserInfoContainer, SubHeading } from "./styles";
import Modal from "../../../../../common/Modal";
import Loading from "../../../../../common/Loading";
import callAPI from "../../../../../api/apiCaller";
import { useParams } from "react-router-dom";
import { Context } from "../../../../../Store";
import Labelize from "../../../../../common/Labelize";
import { convertToLinks, formatDate, unixStampToDate } from "../../../../../common/utils";
import {
  Image,
  Info,
} from "../../ProgramContent/ProgramParticipants/ActiveParticipants/styles";
import styled from "styled-components";
import colors from "../../../../../config/colors";
import { Spacer } from "../../ProgramContent/ProgramOutBounds/style";

const MeetingSpan = styled.span`
  color: ${colors.text};
`;

function MeetingInfo({ data, closeModal }) {
  const params = useParams();
  const [info, setInfo] = React.useState(null);
  const { state, dispatch } = React.useContext(Context);
  //   React.useEffect(()=>{
  //       callAPI(dispatch,"viewMoreMeeting",{rid:userId,id:params.selectedProgram}).then((retrievedInfo)=>{
  //     if(retrievedInfo.isResponseOk)
  //     setInfo(retrievedInfo.data)
  //   })
  // },[])
  if (data === null ) {
    return (
      <UserInfoContainer>
        <Loading loadingItem="Meeting Information" />
      </UserInfoContainer>
    );
  }
// console.log(data,"data");
  return (
    <>
      <UserInfoContainer>
        {
          <Info>
            {data?.user?.image && (
              <Image
                src={
                  data?.user?.image
                    ? data?.user?.image
                    : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"
                }
              />
            )}
            {data?.user?.firstName} {data?.user?.lastName}
          </Info>
        }
        <Spacer />
        <SubHeading>Meeting information</SubHeading>
        <Labelize label={"Location"}>
          <MeetingSpan> {data?.location}</MeetingSpan>
        </Labelize>
        {data?.location === "virtual" ?         
          <Labelize label={"Meeting Link"}>
            <div dangerouslySetInnerHTML={{ __html: convertToLinks(data?.meetingLink) }} />
          </Labelize>
          :
          <Labelize label={"Voucher"}>
            <MeetingSpan> {data?.perk ? data?.perk :'-'}</MeetingSpan>
          </Labelize> 
        }
        <Labelize label={"Reason For Meeting"}>
          <MeetingSpan> {data?.reasonForMeeting}</MeetingSpan>
        </Labelize>
        <Labelize label={"Accepted By"}>
          <MeetingSpan> {data?.Mentor ? data?.Mentor.firstName +' '+ data?.Mentor.lastName  : '-'}</MeetingSpan>
        </Labelize>
        <Labelize label={"Additional Information"}>
          <MeetingSpan dangerouslySetInnerHTML={{ __html: convertToLinks(data?.additionalInfo ? data.additionalInfo : '-') }} />
          {/* {data?.additionalInfo ? data?.additionalInfo :'-'} */}
          {/* </MeetingSpan> */}
        </Labelize>
       {data.meetingScheduleDate ? <>
        <Labelize label={"Meeting Schedule Date"}>
          <MeetingSpan> 
          { unixStampToDate(data.meetingScheduleDate).split(" at ")[0]}
                          <br />
           { unixStampToDate(data.meetingScheduleDate).split(" at ")[1] }
           </MeetingSpan>
        </Labelize>
       </>
        : <>
        <SubHeading>Availability</SubHeading>
        <Labelize label={"Date 1"}>
          <MeetingSpan> 
          { unixStampToDate(data?.date).split(" at ")[0]}
                          <br />
           { unixStampToDate(data?.date).split(" at ")[1] }
           </MeetingSpan>
        </Labelize>
        <Labelize label={"Date 2"}>
        <MeetingSpan> 
          { unixStampToDate(data?.date2).split(" at ")[0]}
                          <br />
           { unixStampToDate(data?.date2).split(" at ")[1] }
           </MeetingSpan>
        </Labelize>
        <Labelize label={"Date 3"}>
        <MeetingSpan> 
          { unixStampToDate(data?.date3).split(" at ")[0]}
                          <br />
           { unixStampToDate(data?.date3).split(" at ")[1] }
           </MeetingSpan>
        </Labelize>
        </>}
      </UserInfoContainer>
    </>
  );
}
export default MeetingInfo;
