import React, { useCallback, useEffect, useState } from "react";
import {
  ButtonWrapper,
  RequestListWrapper,
  RequestsDetailsWrapper,
  RequestsKeyHeader,
  Spacer,
  RequestsKeyHeaders,
  ImagePreview,
  PersonInfo,
  InfoWrapper,
  StatusWraper,
  HeadingWraper,
  RequestHeading,
  OpenNetworkContainer,
  ImagePreviews,
  ButtonsWraper,
  NoprogramContainer,
  NameContainer,
  ProgramInfo,
  RequestListsWrapper,
  ProgramLoaderContainer,
  Clander,
  RequestsKeyHeaderspan,
  RequestsKeyHeaderlocation,
  MeetingWith,
  TimeSection,
  RequestsDetailsWrappers,
  RequestsDetailsWrapperForAdditional,
  ImageContainer,
} from "./style";
import Button from "../../../../../common/Button";
import { Context } from "../../../../../Store";
import callAPI from "../../../../../api/apiCaller";
import NoProgramImage from "../home-program.svg";
import Modal from "../../../../../common/Modal";
import NewRequestForm from "../NewRequest";
import {
  addHourToTime,
  addHourToUnixTimeStamp,
  convertDateToCustomFormat,
  convertToLinks,
  dateToUnixTimeStamp,
  formatDate,
  unixStampToDate,
  unixTImeStampToIsoDate,
} from "../../../../../common/utils";
import { useNavigate, useParams } from "react-router";
import Loading from "../../../../../common/Loading";
import { getUserInfo } from "../../../../../Store/User/accessors";
import { UpcomingCount, CompletedCount, UnacceptedCount, UpcomingTeamCount, CompletedTeamCount } from "../../../../../Store/ProgramMeetingCount/actions";
import {
  ButtonSpacer,
  SectionActions,
} from "../../../../../common/Button/styles";
import DropDown from "../../../../../common/drop-down/DropDown";
import { logDOM } from "@testing-library/react";

function ProgramOutBonds({
  isUpcoming,
  isPast,
  currentTab,
  allProgramMeetingStatistics,
  specificProgramMeetingsStatistics,
  isTeam = false,
  isUnfulfilled
}) {
  const navigate = useNavigate();
  const params = useParams();
  const { dispatch, state } = React.useContext(Context);
  const [openNetworkProgramsMeetings, setOpenNetworkProgramsMeetings] =
    React.useState(null);
  const [openNetworkPrograms, setOpenNetworkPrograms] = React.useState([]);
  const [resetForm, setResetForm] = React.useState({});
  const [showForm, setShowForm] = React.useState(false);
  const [isDeleteModal, setIsDeleteModal] = React.useState(false);
  const [isDeleteRequest, setIsDeleteRequest] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const [isCalenderDropdownActive, setIsCalenderDropdownActive] =
    useState(false);
  const [isCancelRequest, setIsCancelRequest] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState();
  const [isProcess, setIsProcessing] = useState(null);
  const [disableButtonId, setDisableButtonId] = useState("");

  const [selectedMeeting, setSelectedMeeting] = useState(null);

  function handleSelect(item) {
    setSelectedItemId(item.id);
    setIsCalenderDropdownActive((prev) => !prev);
  }

  // iCalendar
  const [eventData, setEventData] = useState({
    eventName: "ICal",
    eventDescription: "This is a sample event description",
  });

  const downloadICS = (icsContent, filename) => {
    const blob = new Blob([icsContent]);
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
  };

  const generateICS = (startDate, endDate, item) => {
    const isMyMente = item?.Mentor?.id === item?.user?.id;
    const { eventName, eventDescription } = eventData;
    //direct link to got calender meeting schedule
    const icsContent = `BEGIN:VCALENDAR\nVERSION:2.0\nPRODID:-// https://add-to-calendar-pro.com // button v2.4.3 //ENCALSCALE:GREGORIAN\nMETHOD:PUBLISH\nBEGIN:VEVENT\nUID:f4ab1cbb-b29d-4dee-83b1-e83cad00a26f\nDTSTAMP:20231009T122014Z\nDTSTART;VALUE=DATE:${startDate}\nDTEND;VALUE=DATE:${endDate}\nSUMMARY:${
      item.program?.title
    } meeting with ${
      isMyMente ? item.meeting.user.firstName : item.Mentor.firstName
    }\nSEQUENCE:0\nSTATUS:CONFIRMED\nCREATED:20231009T122003Z\nLAST-MODIFIED:20231009T122003Z\nEND:VEVENT\nEND:VCALENDAR`;
    downloadICS(icsContent, `${eventName}.ics`);
  };
  const recipientId = params.connectionId;
  function getUpcommingMeetings() {
    !isTeam
      ? callAPI(
          dispatch,
          isUpcoming
            ? params.selectedProgram && recipientId
              ? "upCommingRequestsOneOnOne" 
              : params.selectedProgram
              ? "upCommingRequests"
              : "upCommingForMyAllRequests"
            : params.selectedProgram && recipientId
            ? "pastRequestsOneOnOne"
            : params.selectedProgram
            ? "pastRequests"
            : "pastforAllRequests",
          { id: params.selectedProgram, rid: recipientId }
        ).then((res) => {
          setOpenNetworkProgramsMeetings([]);
          if (res.isResponseOk) {
            if(!isUpcoming && !isUnfulfilled){
              const filteredData = res.data.filter((data) => data.meeting?.meetingScheduleDate || data?.meetingScheduleDate);
              filteredData.sort((a, b) => {
                const timestampA = new Date(a.meeting?.meetingScheduleDate);
                const timestampB = new Date(b.meeting?.meetingScheduleDate);
                return timestampB - timestampA;
              });
              setOpenNetworkProgramsMeetings(filteredData);
              dispatch(CompletedCount(filteredData.length));
            } else if(!isUpcoming && isUnfulfilled){
                const filteredData = res.data.filter((data) => data.meeting?.meetingScheduleDate === null);
                filteredData.sort((a, b) => {
                  const timestampA = new Date(a.meeting?.createdAt);
                  const timestampB = new Date(b.meeting?.createdAt);
                  return timestampB - timestampA;
                });
                setOpenNetworkProgramsMeetings(filteredData);
            }  
            else {
              setOpenNetworkProgramsMeetings(res.data)
              dispatch(UpcomingCount(res.data.length));
            };
          }
        })
      : callAPI(dispatch,isUpcoming ? "teamProgramMeetings" :"teamPastProgramMeetings", {
          id: params.selectedProgram,
          tid: params.connectionId,
        }).then((res) => {
          setOpenNetworkProgramsMeetings([]);
          if (res.isResponseOk) {
            if(isUpcoming){
              dispatch(UpcomingTeamCount(res.data.length));
            } else dispatch(CompletedTeamCount(res.data.length));
            setOpenNetworkProgramsMeetings(res.data);
          }
        });
  }

  useEffect(() => {
    if(!params.selectedProgram){
      callAPI(dispatch, "upCommingForMyAllRequests",{ id: params.selectedProgram, rid: recipientId }).then((res) => {
        if (res.isResponseOk) {
            dispatch(UpcomingCount(res.data.length));
          };
        }
      )
      callAPI(dispatch, "pastforAllRequests", { id: params.selectedProgram, rid: recipientId }).then((res) => {
          if (res.isResponseOk) {
              if(!isUnfulfilled){
                const filteredData = res.data.filter((data) => data.meeting?.meetingScheduleDate || data?.meetingScheduleDate);
                filteredData.sort((a, b) => {
                  const timestampA = new Date(a.meeting?.meetingScheduleDate);
                  const timestampB = new Date(b.meeting?.meetingScheduleDate);
                  return timestampB - timestampA;
                });
                dispatch(CompletedCount(filteredData.length));
              } else {
                const filteredData = res.data.filter((data) => data.meeting?.meetingScheduleDate === null);
                filteredData.sort((a, b) => {
                  const timestampA = new Date(a.meeting?.createdAt);
                  const timestampB = new Date(b.meeting?.createdAt);
                  return timestampB - timestampA;
                });
                dispatch(UnacceptedCount(filteredData.length))
              }
            };
          }
      )
    }
  },[!params.selectedProgram])

  useEffect(() => {
    if(isTeam){
      callAPI(dispatch,"teamProgramMeetings", {
        id: params.selectedProgram,
        tid: params.connectionId,
      }).then((res) => {
        if (res.isResponseOk) {
            dispatch(UpcomingTeamCount(res.data.length));
        }
      });
      callAPI(dispatch,"teamPastProgramMeetings", {
      id: params.selectedProgram,
      tid: params.connectionId,
      }).then((res) => {
        if (res.isResponseOk) {
          dispatch(CompletedTeamCount(res.data.length));
        }
      });
    }
  }, [isTeam, params.connectionId, params.selectedProgram])

  function reassignMentee(item) {
    setDisableButtonId(item.id);
    setIsLoading(true);
    callAPI(dispatch, "reassignMenteeAppHome", {
      id: item?.program?.id,
      mentorId: item?.Mentor?.participant[0]?.id,
      menteeId: item?.meeting?.user?.id,
      meetingId: item?.meeting?.id,
    }).then((res) => {
      setIsLoading(false);
      getUpcommingMeetings();
    });
  }

  function removeItemFromArray(itemToRemove) {
    const updatedArray = openNetworkProgramsMeetings.filter(
      (item) => item.meetingId !== itemToRemove
    );
    setOpenNetworkProgramsMeetings(updatedArray);
  }

  function deleteProgram() {
    setIsDeleteRequest(true);
  // eslint-disable-next-line no-lone-blocks
  {!isTeam &&  selectedMeeting ?
   (   callAPI(dispatch, "trimOutMeetingRequest", {
        rid: selectedMeeting.meetingId,
        id: selectedMeeting.meetingId,
        
        

      }).then(() => {
        setIsDeleteRequest(false);
        removeItemFromArray(selectedMeeting.meetingId);
        setIsDeleteModal(false);
        if (params.selectedProgram) {
          specificProgramMeetingsStatistics();
        } else {
          allProgramMeetingStatistics();
        }
      }))
      :
      (callAPI(dispatch, "deleteTeamMeetingRequest", {
        rid: selectedMeeting.id,
        id: params.selectedProgram,
        tid:params.connectionId,
        

      }).then(() => {
        setIsDeleteRequest(false);
        removeItemFromArray(selectedMeeting.meetingId);
        setIsDeleteModal(false);
        if (params.selectedProgram) {
          specificProgramMeetingsStatistics();
        } else {
          allProgramMeetingStatistics();
        }
      }))}
  }


  function handlePerkBalance(item) {
    setDisableButtonId(item.id);
    setIsProcessing(true);
    callAPI(dispatch, "requestPerk", {
      id: item?.meeting?.id,
      perk: item?.meeting?.perk,
      programId: item?.program?.id,
    }).then(() => {
      // programs();
      getUpcommingMeetings();
      setIsProcessing(false);
    });
  }

  const handleAddToCalender = (item) => (type) => {
    const isMyMente = item?.Mentor?.id === item?.user?.id;
    callAPI(dispatch, "AddtoCalendar", {
      id: item.id,
      addedToCalender: true,
    }).then(() => {});

    const startDate = convertDateToCustomFormat(
      item.meeting?.meetingScheduleDate
    );
    const endDate = convertDateToCustomFormat(
      addHourToUnixTimeStamp(item.meeting?.meetingScheduleDate)
    );
    if (type === "google") {
      const url = `https://calendar.google.com/calendar/u/0/r/eventedit?dates=${startDate}/${endDate}&text=${
        item.program?.title
      } meeting with ${
        isMyMente ? item.meeting.user.firstName : item.Mentor.firstName
      }`;
      window.open(url, "_blank");
    }
    if (type === "ical") {
      generateICS(startDate, endDate, item);
    }
    setIsCalenderDropdownActive((prev) => !prev);
    getUpcommingMeetings();
  };

  const locations = [
    { title: "In person", id: "In person" },
    { title: "virtual", id: "virtual" },
  ];
  // const programs = useCallback(() => {
  //   callAPI(dispatch, "getOpenNetworkPrograms", {id:params.selectedProgram}).then((res) => {
  //     if (res.isResponseOk) {
  //       setOpenNetworkPrograms(res.data);
  //       setResetForm({
  //         ...resetForm,
  //         programId: res.data[0]?.id ? res.data[0]?.id : "",
  //         location: locations[0].id,
  //       });
  //     }
  //   });
  // }, []);

  const handleCancelRequest = (item) => {
    setIsDeleteRequest(true);
    callAPI(dispatch, "cancelMeetingRequest", { id: item.meeting.id }).then(
      () => {
        setIsDeleteRequest(false);
        if (params.selectedProgram) {
          specificProgramMeetingsStatistics();
        } else {
          allProgramMeetingStatistics();
        }
      }
    );

    setIsCancelRequest(false);
    removeItemFromArray(item.id);
  };

  React.useEffect(() => {
    getUpcommingMeetings();
    // programs();
    // to render meeting count
    
    if (params.selectedProgram) {
      specificProgramMeetingsStatistics();
    } else {
      allProgramMeetingStatistics();
    }
    return () => {
      setOpenNetworkProgramsMeetings(null);
    };
  }, [currentTab]);

  if (!isUpcoming && openNetworkProgramsMeetings === null && !isUnfulfilled) {
    return (
      <ProgramLoaderContainer>
        <Loading loadingItem="Completed Meetings" />
      </ProgramLoaderContainer>
    );
  } else if(!isUpcoming && openNetworkProgramsMeetings === null && isUnfulfilled){
    return (
      <ProgramLoaderContainer>
        <Loading loadingItem="Unaccepted Meetings" />
      </ProgramLoaderContainer>
    );
  }else if(openNetworkProgramsMeetings === null){
    return (
      <ProgramLoaderContainer>
        <Loading loadingItem="Upcoming Meetings" />
      </ProgramLoaderContainer>
    );
  }
  if (openNetworkProgramsMeetings.length === 0 && isTeam === false) {
    return (
      <NoprogramContainer>
        <RequestsKeyHeaders size={13} widthSize={300} marginSpace={30}>
          You do not currently have any {isUpcoming ? "Upcoming" : isUnfulfilled ? "Unaccepted" : "Completed"}
          {" "}Meetings.
        </RequestsKeyHeaders>
        <ImagePreviews src={NoProgramImage} />
        <ButtonsWraper></ButtonsWraper>
      </NoprogramContainer>
    );
  }
 
  if (openNetworkProgramsMeetings.length === 0 && isTeam === true) {
    getUpcommingMeetings();
    return (
      <NoprogramContainer>
        <RequestsKeyHeaders size={13} widthSize={300} marginSpace={30}>
          You do not currently have any {isUpcoming ? "Upcoming" : isUnfulfilled ? "Unaccepted" : "Completed"}
          {" "}Team Meetings.
        </RequestsKeyHeaders>
        <ImagePreviews src={NoProgramImage} />
        <ButtonsWraper></ButtonsWraper>
      </NoprogramContainer>
    );
  }

  return (
    <>
      {isUpcoming
        ? openNetworkProgramsMeetings?.map((item, i) => {
          
            const isMyMente = item?.Mentor?.id === item?.user?.id;
            const isMyMentor = item?.Mentor?.id !== item?.user?.id;
            const iamMentor = item?.Mentor?.id === getUserInfo(state).id;
            const programOneOnOne = item?.program?.programType === "1_1_NETWORK";
            return (
              <OpenNetworkContainer key={i}>
                <RequestListWrapper>
                  <PersonInfo size={100} isTeam={isTeam}>
                    {item?.program?.programType !=="TEAM" ? (
                      <ImagePreview
                        size={100}
                        src={
                          item?.meeting?.status === "ACCEPT" && iamMentor 
                          && programOneOnOne && params.connectionId ? item?.meeting?.user?.image
                          : item?.meeting?.status === "ACCEPT" && isMyMente 
                          && programOneOnOne && params.connectionId ? item.Mentor?.image
                            : item?.meeting?.status === "ACCEPT" && isMyMentor
                            ? item.Mentor?.image
                            : item?.meeting?.user?.image &&
                              item?.meeting?.status === "ACCEPT"
                            ? item?.meeting?.user?.image
                            : item?.meeting?.status !== "ACCEPT" &&
                              item.program?.image
                            ? item.program?.image
                            : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"
                        }
                        //as nikky asked After a mentee requests a new meeting, the newly pending
                        // meeting should display on their homescreen with the program logo as the icon.
                        //Currently, the icon is just a white circle. same when they access this from their my connections tab
                        // src={
                        //   item.program?.image
                        //     ? item?.program?.image
                        //     : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"
                        // }
                      />
                    ):
                    
                    <ImagePreview
                    size={100}
                    src={
                     item.team?.media
                        ? item.team?.media
                        : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"
                    }
                    //as nikky asked After a mentee requests a new meeting, the newly pending
                    // meeting should display on their homescreen with the program logo as the icon.
                    //Currently, the icon is just a white circle. same when they access this from their my connections tab
                    // src={
                    //   item.program?.image
                    //     ? item?.program?.image
                    //     : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"
                    // }
                  />
                    }
                    <InfoWrapper>
                      {item?.program?.programType !=="TEAM" ? <StatusWraper>
                        {item?.meeting?.status === "ACCEPT" && (programOneOnOne && params.connectionId && !iamMentor) ? (
                          <>
                          <NameContainer>
                              {item?.Mentor?.firstName}
                            </NameContainer>{" "}
                            <NameContainer>
                              {item?.Mentor?.lastName}
                            </NameContainer>
                          </>
                        )
                        : item?.meeting?.status === "ACCEPT" && (isMyMente || (programOneOnOne && params.connectionId))  ? (
                          <>
                            <NameContainer>
                              {item?.meeting?.user?.firstName}
                            </NameContainer>{" "}
                            <NameContainer>
                              {item?.meeting?.user?.lastName}
                            </NameContainer>
                          </>
                        ) : item?.meeting?.status === "ACCEPT" && !isMyMente  ? (
                          <>
                          <NameContainer>
                              {item?.Mentor?.firstName}
                            </NameContainer>{" "}
                            <NameContainer>
                              {item?.Mentor?.lastName}
                            </NameContainer>
                          </>
                        ) : (
                          <>
                            <NameContainer>
                              {item?.meeting?.status}
                            </NameContainer>
                          </>
                        )}
                      </StatusWraper>
                      :
                     ( <StatusWraper>
                        {item?.status === "ACCEPT" && isMyMente ? (
                          <>
                            <NameContainer>
                              {item?.user?.firstName}
                            </NameContainer>{" "}
                            <NameContainer>
                              {item?.user?.lastName}
                            </NameContainer>
                          </>
                        ) : item?.status === "ACCEPT" && !isMyMente ? (
                          <>
                            <NameContainer>
                              {item?.Mentor?.firstName}
                            </NameContainer>{" "}
                            <NameContainer>
                              {item?.Mentor?.lastName}
                            </NameContainer>
                          </>
                        ) : (
                          <>
                            <NameContainer>
                              {item?.status}
                            </NameContainer>
                          </>
                        )}
                      </StatusWraper>)}
                      <HeadingWraper>{item.program?.title}</HeadingWraper>
                      {item.program.programType ==="TEAM" && (
                        <MeetingWith>
                          {item?.meetingWith==='ALL_MEMBERS_AND_MENTOR' ? 'All Member,Mentors' :item?.meetingWith}
                        </MeetingWith>
                      )}
                    </InfoWrapper>
                  </PersonInfo>
                  <Spacer></Spacer>
                  <ProgramInfo>
                    <RequestsKeyHeader textAlign={true}>
                    <RequestsKeyHeaderlocation>Reason for Meeting:</RequestsKeyHeaderlocation><br/>
                      {item.program.programType ==="TEAM" ? item?.reasonForMeeting : item?.meeting?.reasonForMeeting}
                    </RequestsKeyHeader>{" "}
                    {item?.meeting?.meetingScheduleDate !== null ? (
                      <RequestsKeyHeaders textAlign={true}>
                        {
                          unixStampToDate(
                            item.program.programType ==="TEAM"
                              ? item?.meetingScheduleDate
                              : item?.meeting?.meetingScheduleDate
                          ).split(" at ")[0]
                        }
                        <br />
                        {
                          unixStampToDate(
                            item.program.programType ==="TEAM"
                              ? item?.meetingScheduleDate
                              : item?.meeting?.meetingScheduleDate
                          ).split(" at ")[1]
                        }
                      </RequestsKeyHeaders>
                    ) : (
                      <>
                        <RequestsKeyHeaders textAlign={true} fontStyles={"italic"}>
                          Availability
                        </RequestsKeyHeaders>
                        <>
                        <RequestsDetailsWrapper fontStyles={"italic"}>
                          {
                            unixStampToDate(item?.meeting?.date).split(
                              " at "
                            )[0]
                          }
                          <br />
                          {
                            unixStampToDate(item?.meeting?.date).split(
                              " at "
                            )[1]
                          }
                        </RequestsDetailsWrapper>
                        <RequestsDetailsWrapper fontStyles={"italic"}>
                          {
                            unixStampToDate(item?.meeting?.date2).split(
                              " at "
                            )[0]
                          }
                          <br />
                          {
                            unixStampToDate(item?.meeting?.date2).split(
                              " at "
                            )[1]
                          }
                        </RequestsDetailsWrapper>
                        <RequestsDetailsWrapper fontStyles={"italic"}>
                          {
                            unixStampToDate(item?.meeting?.date3).split(
                              " at "
                            )[0]
                          }
                          <br />
                          {
                            unixStampToDate(item?.meeting?.date3).split(
                              " at "
                            )[1]
                          }
                        </RequestsDetailsWrapper>
                        </>
                      </>
                    )}
                    <RequestsDetailsWrapper>
                      <RequestsKeyHeaderlocation isTeam={isTeam}>
                        {" "}
                        Location:{" "}
                      </RequestsKeyHeaderlocation>
                      <RequestsKeyHeaderspan widthSize={1} isTeam={isTeam} left={true}>
                        {item.program.programType ==="TEAM" ? item?.location:item?.meeting?.location}
                      </RequestsKeyHeaderspan>{" "}
                    </RequestsDetailsWrapper>
                    {item?.program?.programType === "OPEN_NETWORK" &&
                      <RequestsDetailsWrapperForAdditional width={50}>
                      <RequestsKeyHeaderlocation isTeam={isTeam}>
                        {" "}
                        Meeting Link:
                      </RequestsKeyHeaderlocation>{" "}
                      <RequestsKeyHeaderspan isTeam={isTeam}
                        height={
                          item?.meeting?.meetingLink?.length >= 93
                            ? true
                            : false
                        }
                      dangerouslySetInnerHTML={{ __html: convertToLinks(
                       item.program?.programType === "TEAM" ? item?.meetingLink : item?.meeting?.meetingLink)}} />
                        {/* {item.program.programType ==="TEAM" ? item?.additionalInfo : item?.meeting?.additionalInfo} */}
                      {/* </RequestsKeyHeaderspan> */}
                    </RequestsDetailsWrapperForAdditional>
                    }
                    
                    <RequestsDetailsWrapperForAdditional width={50}>
                      <RequestsKeyHeaderlocation isTeam={isTeam}>
                        {" "}
                        Additional Comments:
                      </RequestsKeyHeaderlocation>{" "}
                      <RequestsKeyHeaderspan isTeam={isTeam}
                        height={
                          item?.meeting?.additionalInfo?.length >= 93
                            ? true
                            : false
                        }
                      dangerouslySetInnerHTML={{ __html: convertToLinks(
                       item.program?.programType === "TEAM" ? item?.additionalInfo : item?.meeting?.additionalInfo)}} />
                        {/* {item.program.programType ==="TEAM" ? item?.additionalInfo : item?.meeting?.additionalInfo} */}
                      {/* </RequestsKeyHeaderspan> */}
                    </RequestsDetailsWrapperForAdditional>
                  </ProgramInfo>
                </RequestListWrapper>

                {item.program.programType !=="TEAM" && <ButtonWrapper>
                  {item?.meeting?.status !== "PENDING" ? (
                    <>
                      {
                        <DropDown
                          id={item.id}
                          title={
                            item.addedToCalender
                              ? "Added to Calendar"
                              : "Add To Calendar"
                          }
                          options={[
                            { type: "google", title: "Google" },
                            { type: "ical", title: "iCal" },
                          ]}
                          icon="calendar"
                          isActive={isCalenderDropdownActive}
                          handleDropdown={() => {
                            handleSelect(item);
                          }}
                          selectedItemId={selectedItemId}
                          handler={handleAddToCalender(item)}
                          variant={item.addedToCalender}
                          isDisable={item.addedToCalender}
                        />
                      }
                     {/* {isTeam &&  <Button
                        text={"Edit"}
                        variant="secondary"
                        // disabled={!validateIsValid(isValid)}
                        onClick={() => {
                          setShowForm(item);
                        }}
                        isNotRound={true}
                        icon={"edit"}
                      />} */}
                    {item.program.programType !=="TEAM" &&  <Button
                        text={"Message"}
                        variant="secondary"
                        onClick={() =>
                          navigate(
                            `/messages/${
                              !iamMentor
                                ? item?.Mentor?.uuid
                                : item?.meeting?.user?.uuid
                            }`
                          )
                        }
                        isNotRound={true}
                        icon={"chat"}
                      />}


                      {/* {(item?.meeting?.perk === "coffee" &&
                        item.program?.programBalance?.availableCoffees > 0 &&
                        !item?.meeting?.perkProvided && !iamMentor) ||
                      (item?.meeting?.perk === "lunch" &&
                        item?.meeting?.perk !== null &&
                        item.program?.programBalance?.availableLunch > 0 &&
                        !item?.meeting?.perkProvided && !iamMentor) ? (
                        <Button
                          text={`Request ${item?.meeting?.perk} Voucher`}
                          variant="secondary"
                          disabled={item?.meeting?.perkProvided}
                          onClick={() => handlePerkBalance(item)}
                          isNotRound={true}
                          isTH={true}
                          icon={item.id === disableButtonId && isProcess ? 'loading' : "filter"}
                        />
                      ) : (
                        item?.meeting?.perk && !iamMentor && (
                          <Button
                            text={`Request ${item?.meeting?.perk} Voucher`}
                            variant="secondary"
                            disabled={item?.meeting?.perkProvided}
                            onClick={() => handlePerkBalance(item)}
                            isNotRound={true}
                            isTH={true}
                            icon={item.id === disableButtonId && isProcess ? 'loading' : "filter"}

                          />
                        )
                      )} */}
                      {/* <Button
                        text={"Cancel"}
                        variant="secondary"
                        // disabled={!validateIsValid(isValid)}
                        // onClick={()=>handleCancelRequest(item)}
                        onClick={() => setIsCancelRequest(true)}
                        isNotRound={true}
                        icon={"cross"}
                      /> */}
                    </>
                  ) : (
                    <>
                      <Button
                        text={"Edit"}
                        variant="secondary"
                        // disabled={!validateIsValid(isValid)}
                        onClick={() => {
                          setShowForm(item);
                        }}
                        isNotRound={true}
                        icon={"edit"}
                      />
                    </>
                  )}
                  <Button
                    text={"Cancel"}
                    variant="secondary"
                    // disabled={!validateIsValid(isValid)}
                    onClick={() => {
                      setIsDeleteModal(item);
                      setSelectedMeeting(item);
                    }}
                    isNotRound={true}
                    icon={"cross"}
                  />
                </ButtonWrapper>}
                {isDeleteModal && (
                  <Modal
                    title={"Are you sure you want to proceed?"}
                    onClose={() => setIsDeleteModal(false)}
                    isDelete={true}
                  >
                    <SectionActions>
                      <Button
                        icon={isDeleteRequest ? "loading" : "trash"}
                        variant="danger"
                        onClick={() => deleteProgram()}
                        text="Yes, cancel meeting"
                      />
                      <ButtonSpacer />
                      <Button
                        icon="tick"
                        variant="primary"
                        onClick={() => setIsDeleteModal(false)}
                        text="No, Keep the meeting"
                      />
                    </SectionActions>
                  </Modal>
                )}
                {isCancelRequest && (
                  <Modal
                    title={"Are you sure you want to Cancel this Meeting?"}
                    onClose={() => setIsCancelRequest(false)}
                  >
                    <SectionActions>
                      <Button
                        icon={isDeleteRequest ? "loading" : "tick"}
                        variant="danger"
                        onClick={() => handleCancelRequest(item)}
                        text="Yes"
                      />
                      <ButtonSpacer />
                      <Button
                        icon="cross"
                        variant="primary"
                        onClick={() => setIsCancelRequest(false)}
                        text="No"
                      />
                    </SectionActions>
                  </Modal>
                )}
              </OpenNetworkContainer>
            );
          })
        : isPast && openNetworkProgramsMeetings !== null
        ? openNetworkProgramsMeetings.map((item, i) => {
            const isMyMentor = item?.Mentor?.id === item?.user?.id;
            const isNotMyMentor = item?.Mentor?.id !== item?.user?.id;
            //doing this below condition incase i am mentor and i have to send message to mentee
            //else if i am mentee then need to send message to my mentor
            const iamMentor = item?.Mentor?.id === getUserInfo(state).id;

            return (
              <OpenNetworkContainer key={i}>
                <RequestListsWrapper>
                  <PersonInfo size={100} isTeam={item.program.programType ==="TEAM"}  >
                   {item.program.programType !== "TEAM" ?
                   <ImageContainer>
                    <ImagePreview
                      size={60}
                      src={
                        item.status === "PENDING"
                          ? item.program.image
                          : iamMentor
                          ? item?.meeting?.user?.image
                          : isNotMyMentor
                          ? item.Mentor?.image 
                          : item?.meeting?.user?.image
                          ? item?.meeting?.user?.image
                          : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"
                      }
                    />
                    </ImageContainer>
                    :
                    <ImageContainer size={60}>
                    <ImagePreview
                      size={60}
                      src={
                        item?.team?.media ? item.team.media
                          : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"
                      }
                  />
                  </ImageContainer>
                    }
                    <InfoWrapper>
                      <StatusWraper>
                    {item.program.programType !=="TEAM" && <>
                      {item?.meeting?.status === "ACCEPT" && iamMentor ? (
                          <>
                            <NameContainer>
                              {item?.meeting?.user?.firstName}
                            </NameContainer>{" "}
                            <NameContainer>
                              {item?.meeting?.user?.lastName}
                            </NameContainer>
                          </>
                        ) :
                        item?.meeting?.status === "ACCEPT" && isMyMentor ? (
                          <>
                            <NameContainer>
                              {item?.meeting?.user?.firstName}
                            </NameContainer>{" "}
                            <NameContainer>
                              {item?.meeting?.user?.lastName}
                            </NameContainer>
                          </>
                        ) : item?.meeting?.status === "ACCEPT" && item.program.programType !=="TEAM" &&
                          !isMyMentor ? (
                          <>
                            <NameContainer>
                              {item?.Mentor?.firstName}
                            </NameContainer>{" "}
                            <NameContainer>
                              {item?.Mentor?.lastName}
                            </NameContainer>
                          </>
                        ) :  item?.meeting?.status === "ACCEPT " ? (
                          item?.meeting?.status
                        ) : (
                          "Unaccepted"
                        )}
                        </>}

                        {item.program.programType ==="TEAM" && ( item?.status === "ACCEPT" ? (
                          <>
                            <NameContainer>
                              {item?.team?.name}
                            </NameContainer>
                           
                          </>
                        )  
                       // :item?.status === "ACCEPT" &&
                         // !isMyMentor ? (
                          //<>
                            //<NameContainer>
                           //   {item?.Mentor?.firstName}
                            //</NameContainer>{" "}
                            //<NameContainer>
                             // {item?.Mentor?.lastName}
                            //</NameContainer>
                          //</>
                        //)
                         : item?.status === "ACCEPT " ? (
                          item?.status
                        ) : (
                          "Unaccepted"
                        ))}
                      </StatusWraper>
                      <HeadingWraper>{item.program?.title} </HeadingWraper>
                    </InfoWrapper>
                    {item.program.programType !=="TEAM" && item?.meeting.status !== "ACCEPT" && (
                      <RequestHeading>
                       <RequestsKeyHeader> Requested on:</RequestsKeyHeader>
                        {unixStampToDate(
                          dateToUnixTimeStamp(isTeam ? item?.createdAt : item?.meeting?.createdAt)
                        )}
                      </RequestHeading>
                    )}

{/* { item.program.programType ==="TEAM" && item?.status === "ACCEPT" && (
                      <RequestHeading>
                        Requested on:{" "}
                       <RequestsKeyHeaderspan> {unixStampToDate(
                          dateToUnixTimeStamp( item?.createdAt)
                        )}
                        </RequestsKeyHeaderspan>
                      </RequestHeading>
                    )} */}
                  </PersonInfo>
                  <Spacer></Spacer>
                  {item.program.programType ==="TEAM" && <RequestsKeyHeaderlocation>Reason for Meeting:</RequestsKeyHeaderlocation>}
                  <RequestsKeyHeaderspan isTeam={isTeam} textAlign={true}>
                    {item.program.programType ==="TEAM" ?item?.reasonForMeeting : item?.meeting?.reasonForMeeting}
                    <br/>
                   {item.program.programType ==="TEAM" && (item?.meetingWith==='ALL_MEMBERS_AND_MENTOR' ? 'All Member,Mentors' :item?.meetingWith)}
                  </RequestsKeyHeaderspan>{" "}
                  {item?.meeting?.status === "ACCEPT" ? (
                    <>
                      <RequestsKeyHeaders>
                        {
                          unixStampToDate(
                            item.program.programType ==="TEAM"
                              ? item?.meetingScheduleDate
                              : item?.meeting?.meetingScheduleDate
                          ).split(" at ")[0]
                        }
                        <br />
                        {
                          unixStampToDate(
                            item.program.programType ==="TEAM"
                            ? item?.meetingScheduleDate
                            : item?.meeting?.meetingScheduleDate
                          ).split(" at ")[1]
                        }
                      </RequestsKeyHeaders>
                    </>
                  ) :item.program.programType ==="TEAM" ?<></>:  (
                    <>
                      <RequestsKeyHeaders fontStyles={"italic"}>
                        Availability
                      </RequestsKeyHeaders>
                      <RequestsDetailsWrapper fontStyles={"italic"}>
                        {unixStampToDate(item?.meeting?.date).split(" at ")[0]}
                        <br />
                        {unixStampToDate(item?.meeting?.date).split(" at ")[1]}
                      </RequestsDetailsWrapper>
                      <RequestsDetailsWrapper fontStyles={"italic"}>
                        {unixStampToDate(item?.meeting?.date2).split(" at ")[0]}
                        <br />
                        {unixStampToDate(item?.meeting?.date2).split(" at ")[1]}
                      </RequestsDetailsWrapper>
                      <RequestsDetailsWrapper fontStyles={"italic"}>
                        {unixStampToDate(item?.meeting?.date3).split(" at ")[0]}
                        <br />
                        {unixStampToDate(item?.meeting?.date3).split(" at ")[1]}
                      </RequestsDetailsWrapper>
                    </>
                  )}
                  <RequestsDetailsWrapper isTeam={isTeam}>
                    <RequestsKeyHeaderlocation widthSize={1} >
                      {" "}
                      Location:{" "}
                    </RequestsKeyHeaderlocation>{" "}
                   <RequestsKeyHeaderspan left={true}>
                    {item.program.programType ==="TEAM" ?item.location : item?.meeting?.location}
                    </RequestsKeyHeaderspan>
                  </RequestsDetailsWrapper>
                  {item?.meeting?.meetingLink && item?.program?.programType === "OPEN_NETWORK" && (
                    <RequestsDetailsWrappers>
                    <RequestsKeyHeaderlocation widthSize={1} >
                      {" "}
                      Meeting Link:{" "}
                    </RequestsKeyHeaderlocation>
                      <div style={{ fontWeight:"normal" }} dangerouslySetInnerHTML={{ __html:
                       convertToLinks(
                        item?.meeting?.meetingLink
                        ? item?.meeting?.meetingLink
                        : undefined
                       )}} />
                    </RequestsDetailsWrappers>
                  )}
                  {item?.meeting?.additionalInfo && (
                    <RequestsDetailsWrappers>
                      <RequestsKeyHeader>
                        {" "}
                        Additional Comments:{" "}
                      </RequestsKeyHeader>
                      <div style={{ fontWeight:"normal" }} dangerouslySetInnerHTML={{ __html:
                       convertToLinks(
                        item?.meeting?.additionalInfo
                        ? item?.meeting?.additionalInfo
                        : undefined
                       )}} />

                      {/* {item?.meeting?.additionalInfo
                        ? item?.meeting?.additionalInfo
                        : undefined} */}
                    </RequestsDetailsWrappers>
                  )}
                </RequestListsWrapper>
                {item?.meeting?.status === "ACCEPT" && (
                  <ButtonWrapper>
                    <Button
                      text={"Message"}
                      variant="secondary"
                      // disabled={!validateIsValid(isValid)}
                      onClick={() => {
                        navigate(
                          `/messages/${
                            !iamMentor
                              ? item?.Mentor?.uuid
                              : item?.meeting?.user?.uuid
                          }`
                        );
                      }}
                      isNotRound={true}
                      icon={"chat"}
                    />
                    {
                      <Button
                        text={"Track Hours"}
                        variant={
                          item?.meeting?.trackHourAssigned === true
                            ? "primary"
                            : "secondary"
                        }
                        disabled={
                          item?.meeting?.trackHourAssigned === true
                            ? true
                            : false
                        }
                        isTH={true}
                        onClick={() => reassignMentee(item)}
                        isNotRound={true}
                        icon={
                          item.id === disableButtonId && isLoading
                            ? "loading"
                            : item?.meeting?.trackHourAssigned === true
                            ? "tick"
                            : "time"
                        }
                      />
                    }
                  </ButtonWrapper>
                )}
              </OpenNetworkContainer>
            );
          })
        : ""}
      {showForm && (
        <Modal onClose={() => setShowForm(false)}>
          <NewRequestForm
            openNetworkPrograms={openNetworkPrograms}
            resetForm={resetForm}
            setResetForm={setResetForm}
            setShowForm={setShowForm}
            editForm={showForm}
            isEdit={true}
          />
        </Modal>
      )}
    </>
  );
}

export default ProgramOutBonds;
