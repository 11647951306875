import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import Button from "../../../../../../common/Button";
import {
  ConnectionCardContainer,
  ConnectionName,
  ConnectionActions,
  ConnectionImage,
  TaskCounter,
  ConnectionLine,
} from "./styles";
import callAPI from "../../../../../../api/apiCaller";
import { ButtonSpacer } from "../../../../../../common/Button/styles";
import { Context } from "../../../../../../Store";
import features from "../../../../../../config/features";

function ConnectionCard({ role, user, type = "connect", refresh }) {
  let params = useParams();
  params = { ...params, userRole: role };
  const [isActioning, setIsActioning] = React.useState(false);
  const { state, dispatch } = React.useContext(Context);
  let navigate = useNavigate();
  return (
    <ConnectionCardContainer>
      <ConnectionImage
        image={
          user?.participant?.image
            ? user?.participant?.image
            : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"
        }
        alt={
          "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"
        }
      />
      <ConnectionLine>
        <ConnectionName>{user?.participant?.firstName+" "+user?.participant?.lastName}</ConnectionName>
        {type === "connect" && user.requestCount !== 0 && (
          <TaskCounter></TaskCounter>
        )}
      </ConnectionLine>
      <ConnectionActions>
        {type === "connect" && (
          <Button
            text="Connect"
            onClick={() =>
              navigate(
                `/programs/${params.selectedProgram}/connections/${
                  user?.participant?.id
                }/${
                  features?.updates ? "updates" : features?.meetings ? 'meetings' :"tasks"
                }?role=${role}&recipientId=${
                   user?.id 
                }`
              )
            }
          />
        )}
        {type === "request" && (
          <>
            <Button
              icon={isActioning === "approve" ? "loading" : "tick"}
              variant="primary"
              onClick={() => {
                setIsActioning("approve");
                callAPI(dispatch, "approveRequest").then(() => {
                  refresh();
                });
              }}
            />
            <ButtonSpacer />
            <Button
              icon={isActioning === "deny" ? "loading" : "cross"}
              onClick={() => {
                setIsActioning("deny");
                callAPI(dispatch, "denyRequest").then(() => {
                  refresh();
                });
              }}
            />
          </>
        )}
      </ConnectionActions>
    </ConnectionCardContainer>
  );
}
export default ConnectionCard;
