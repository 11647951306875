import styled from "styled-components";
import colors from "../../config/colors";

export const ModalContainer = styled.div`
    width=${props=>props.isWidth && "300px" }
    max-width=${props=>props.isWidth && "300px" }
`;

export const ModalBackground = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(10px);
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 20px;
`;
export const ModalContent = styled.div`
    background-color: ${colors.background};
    padding: 20px;
    width: ${props=>props.fixedWidth && props.fixedWidth};
    min-width:${props=>props.minWidth ?props.minWidth :'30vw'};
    max-width:${props=>props.width ? props.width : ''};
    height:${props=>props.isHeight ? props.isHeight : ''};
    border-radius:10px;
    position: relative;
    overflow-y: auto;
    max-width: 95%;
    
    @media(max-width:767px){
        align-self: self-start;
        max-height: 76vh;
        width: ${props=>props.fixedWidth && "auto"};
    }
`;

export const ModalTitle = styled.div`
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
`;
export const ModalActions = styled.div`
    display: flex;
    align-items: center;
    justify-content:flex-end;
    margin-top: 20px;
    @media (max-width:480px){
        justify-content: center; 
        flex-wrap: wrap;
        gap: 10px;
    }
`;
