import createReducer from "../common/createReducer";

var reducers = {
    UpcomingMeetingsCount:(state, action) => {
        return {
            ...state,
            count:action.count,
        };
    },
    CompletedMeetingsCount:(state, action) => {
        return {
            ...state,
            count:action.count,
        };
    },
    UnacceptedMeetingCount:(state, action) => {
        return {
            ...state,
            count:action.count,
        };
    },
    UpcomingTeamMeetingCount:(state, action) => {
        return {
            ...state,
            count:action.count,
        };
    },
    CompletedTeamMeetingCount:(state, action) => {
        return {
            ...state,
            count:action.count,
        };
    },
    RequestedMeetingCount:(state, action) => {
        return {
            ...state,
            count: action.count,
        };
    },
};


const initialState = {
  count:0,
};

export default createReducer(initialState, reducers);