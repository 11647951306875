import React, { useEffect, useState } from "react";
import {
  Actions,
  BodyContainer,
  CardContainer,
  EditTeam,
  Heading,
  ImageBox,
  ImageShow,
  MemberSpan,
  NameHeading,
  ParticipantTeams,
  TeamContainer,
  TeamInfo,
  TeamNameSpan,
} from "./style";
import Button from "../../../../common/Button";
import TextInput from "../../../../common/TextInput";
import ImageUpload from "../../../../common/ImageUpload";
import callAPI from "../../../../api/apiCaller";
import { Context } from "../../../../Store";
import { ImagePreview } from "../../../../common/ImageUpload/styles";
import Loading from "../../../../common/Loading";
import TeamInfoCard from "./TeamInfoCard";
import { ActiveParticipantsContainer } from "../ProgramContent/ProgramParticipants/ActiveParticipants/styles";
import { useParams } from "react-router-dom";
import { isImageUrl } from "../../../../common/utils";

const TeamParticipant = ({setSpecificProgram, searchText}) => {
  const url = window.location.href;
  const parts = url.split("/"); // Split the URL by '/'
  const id = parts[parts.length - 2]; // Get the second-to-last part of the URL
  const programId = parts[parts.indexOf("programs") + 1];
  const params= useParams()
  const [teamInfo, setTeamInfo] = useState({
    name: "",
    media: "",
    programId: +params.selectedProgram,
  });

  const [teamToEdit, setTeamToEdit] = useState({

    id:"",
    name:"",
    media:"",
    programId: +params.selectedProgram,
  })
  const [loading, setLoading] = useState(false);
  const [teams, setTeams] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [selectedTeamId, setSelectedTeamId] = useState(null);
  const { state, dispatch } = React.useContext(Context);

  const handleInputChange = (key, value) => {
    setTeamInfo({
      ...teamInfo,
      [key]: value,
    });
  };
  function updateField(field) {
    return function (newValue) {
      setTeamToEdit({
        ...teamToEdit,
        [field]: newValue,
      });
    };
  }

  function getMembers() {
    callAPI(dispatch, "getTeamMembers", {
      id: programId,
    }).then((res) => {
      setTeams(res.data);
      setLoading(false);
    });
  }

  function AddTeamMembers() {
    setLoading(true);
    callAPI(dispatch, "AddTeamMember", { ...teamInfo }).then((res) => {
      setLoading(false);
      setTeamInfo({
        name:"",
        media:"",
        programId: +params.selectedProgram,
      });
      
      getMembers();

    });
  }
//update Api  for teams
  function UpdateTeam() {
    const updatedTeamToEdit = { ...teamToEdit, media: teamToEdit.media };
    //if issue faced for about image update double url
    if(isImageUrl(updatedTeamToEdit.media)) {
      delete updatedTeamToEdit.media
    }
    callAPI(dispatch, "UpdateTeamMember", {
      ...updatedTeamToEdit,
      id: selectedTeamId,
    })
      .then((res) => {
       setIsEdit(false)
        getMembers();
      })
      .catch((error) => {
       setIsEdit(false)
        console.error("Error updating board:", error);
      });
  }


  const handleEditTeamClick = (item) => {
    setIsEdit(item.id);
    setSelectedTeamId(item.id);
    setTeamToEdit({
      id: item.id,
      name: item.name,
      media: item.media,
      programId: item.programId,
    });
  };


  useEffect(() => {
    getMembers();
  }, []);

  if (teams===null) {
    return (
      <ActiveParticipantsContainer>
        <Loading loadingItem="Teams" />
      </ActiveParticipantsContainer>
    );
  }
  return (
    <ParticipantTeams>
      <TeamContainer height={'340px'}>
        <Heading heading={'20px'}>{"Add a New Team"}</Heading>
        <ImageBox>
          <ImageUpload
            value={teamInfo.media}
            setValue={(value) => handleInputChange('media',value)}
            size={120}
            teamVariant={true}
            newTeam={true}
          />
        </ImageBox>
        <TeamInfo>
          <TextInput
            value={teamInfo.name}
            placeholder="Team Name"
            setValue={(value) => handleInputChange("name", value)}
          />
    
        </TeamInfo>

        <Button
          text={"Add"}
          variant="primary"
          width={"10px"}
          icon={loading ? "loading" : "add"}
          // onClick={() => AddTeamMembers()}
          onClick={() => AddTeamMembers()}
        />

      </TeamContainer>
      {teams
        .filter(member => 
          member?.name?.toLowerCase().includes(searchText.toLowerCase()) ||
          member?.teamMembers?.some(tm =>
            tm?.user?.firstName.toLowerCase().includes(searchText.toLowerCase()) ||
            tm?.user?.lastName.toLowerCase().includes(searchText.toLowerCase())
          )
        )
        .map((member, i) => {
          return (
            <TeamInfoCard 
              member={member} 
              key={i}
              isEdit={isEdit}
              setTeamToEdit={setTeamToEdit}
              teamInfo={teamToEdit}
              loading={loading}
              setIsEdit={setIsEdit}
              handleEditTeam={() => handleEditTeamClick(member)}
              updateField={updateField}
              UpdateTeam={() => UpdateTeam()}
              setSpecificProgram={setSpecificProgram}
            />
          );
        })
      }
    </ParticipantTeams>
  );
};

export default TeamParticipant;
