import React, { useState } from "react";
import Icon from "../Icon";
import {
    ActionMenuContainer,
    ActionIcon,
    ActionMenuList,
    List
} from './style'

const ActionMenu = ({onClick, count}) => {
    const[ collapse, setCollapse ] = useState(false);
  return (
    <>
        <ActionMenuContainer>
            <ActionIcon onClick={() => {setCollapse(!collapse)}}>
                <Icon type='vertical-dots' />
            </ActionIcon>
            {collapse && 
                <ActionMenuList>
                    <List>
                        <li onClick={() => onClick(true)}>
                          View Unaccepted Meetings
                          {count && <span>{count}</span>}
                        </li>
                    </List>
                </ActionMenuList>
            }
        </ActionMenuContainer>
    </>
  );
};

export default ActionMenu;
